import { FC, ReactNode, memo } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  Align,
  Display,
  Whitespace,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Text from "apps/website/components/base/Text/Text";
import { WithTestID } from "apps/website/types";

import { Size, tagSizeMap } from "./Tag.map";

export interface ITagProps {
  theme?: Theme;
  text: string;
}

export interface ITag extends WithTestID {
  theme?: Theme;
  children: ReactNode;
  size?: Size;
  className?: string;
  display?: Display;
  whitespace?: Whitespace;
  align?: Align;
}

const Tag: FC<ITag> = ({ "data-testid": testId, theme = "default", size = "default", className, whitespace = "noWrap", children, display = "title", align = "center" }) => {

  const computedClasses = `${tagSizeMap[size].classes} ${themeRootClassMap[theme]}`;

  return (
    <div data-component="Tag" data-theme={theme} className={`inline-flex w-fit ${computedClasses} ${className}`} data-testid={testId}>
      <Text tag="div" display={display} size={legacySizeCollectionMap[tagSizeMap[size].font]} whitespace={whitespace} align={align} className="w-full">{ children }</Text>
    </div>
  );
};

export default memo(Tag);
